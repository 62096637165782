import ky from 'ky';

const isProd = (env) => env.toLowerCase() !== 'develop' && env.toLowerCase() !== 'demo'

const API = {
    token: '',
    env: '.develop',
    setEnv: (env) => {
        if (isProd(env)) API.env = '';
        else API.env = `.${env.toLowerCase()}`;
    },
    ingest: (streamId, frames) => ky.post(`https://ingest${API.env === '' ? '.' : '-'}api${API.env}.leanspace.io/streams/${streamId}/telemetry`, { json: { frames }, headers: { 'Authorization': `Bearer ${API.token}` } }).json(),
    getStream: (streamId) => ky.get(`https://api${API.env}.leanspace.io/streams-repository/streams/${streamId}`, { headers: { 'Authorization': `Bearer ${API.token}` } }).json()
}

export default API;