import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SendIcon from '@material-ui/icons/Send';
import StopIcon from '@material-ui/icons/Stop';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import API from './api';
import { encodeTextToBase64 } from './convertInput';
import EnvToggle from './EnvToggle';

const contentLabels = {
  'csvstring': 'Comma separated values, one packet per line',
  'csvfile': '',
  'base64': 'One packet per line as base64 encoded binaries',
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8)
  },
}));

function App() {
  const classes = useStyles();

  const [env, setEnv] = useState(localStorage.getItem('env') || 'DEVELOP');
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [streamId, setStreamId] = useState(localStorage.getItem('streamId') || '');
  const [contentFormat, setContentFormat] = useState('csvstring');
  const [packetContent, setPacketContent] = useState(localStorage.getItem('packetContent') || '');
  const [fileRef, setFileRef] = useState(null);

  const [isSending, setIsSending] = useState(false);
  const [isSendingContinuously, setIsSendingContinuously] = useState(false);
  const continuousSendingRef = useRef(false);
  const [framesSentCount, setFramesSentCount] = useState(0);
  const [lastError, setLastError] = useState();

  useEffect(() => {
    if (localStorage.getItem('token')) API.token = localStorage.getItem('token');
    if (localStorage.getItem('env')) API.setEnv(localStorage.getItem('env'));
  }, [])

  const toggleContinuous = useCallback(() => {
    setIsSendingContinuously(!isSendingContinuously);
    continuousSendingRef.current = !continuousSendingRef.current;
  }, [isSendingContinuously, continuousSendingRef])

  const sendFrame = useCallback(async () => {
    try {
      setLastError(null);
      let frames = [];

      if (contentFormat === 'base64') {
        frames = packetContent.split("\n");
      }
      if (contentFormat === 'csvstring' || contentFormat === 'csvfile') {
        const stream = await API.getStream(streamId);
        frames = await encodeTextToBase64(contentFormat === 'csvstring' ? packetContent : fileRef, stream)
      }

      await API.ingest(streamId, frames)
      setFramesSentCount(count => count + frames.length);
      if (continuousSendingRef.current) setTimeout(sendFrame, 1000);//TODO make this configurable
    }
    catch (error) {
      setLastError(error.toString());
      if (continuousSendingRef.current) toggleContinuous();
    }
  }, [contentFormat, streamId, packetContent, fileRef, toggleContinuous]);

  const sendSingle = async () => {
    setIsSending(true);
    await sendFrame();
    setIsSending(false);
  }

  useEffect(() => {
    if (isSendingContinuously) sendFrame();
  }, [isSendingContinuously, sendFrame]);

  const updateField = (fieldName, fieldValue) => {
    localStorage.setItem(fieldName, fieldValue);
    if (fieldName === 'token') {
      setToken(fieldValue);
      API.token = fieldValue;
    }
    else if (fieldName === 'env') {
      setEnv(fieldValue)
      API.setEnv(fieldValue);
    }
    else if (fieldName === 'streamId') setStreamId(fieldValue);
    else if (fieldName === 'packetContent') setPacketContent(fieldValue);
  }

  const baseConsoleUrl = env === 'CONSOLE' ? 'https://console.leanspace.io' : `https://${env.toLowerCase()}.leanspace.io`

  return (
    <>
      <EnvToggle value={env} onChange={newEnv => updateField('env', newEnv)} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Card className={classes.paper}>
          <CardContent>

            <TextField value={token} onChange={event => updateField('token', event.target.value)} label="Token" fullWidth helperText={<a href={`${baseConsoleUrl}/settings`} target="_blank" rel="noreferrer">Get it from here</a>} disabled={isSendingContinuously} />
            <TextField onChange={event => updateField('streamId', event.target.value)} value={streamId} label="Stream ID" fullWidth helperText={<a href={`${baseConsoleUrl}/services/data/streams/list`} target="_blank" rel="noreferrer">List of streams</a>} disabled={isSendingContinuously} />
          </CardContent>
          <CardContent>
            <InputLabel shrink id="format-label">
              Format
            </InputLabel>
            <Select
              labelId="format-label"
              value={contentFormat}
              onChange={(event) => setContentFormat(event.target.value)}
              fullWidth
            >
              <MenuItem value={'csvstring'}>CSV Text</MenuItem>
              <MenuItem value={'csvfile'}>CSV File</MenuItem>
              <MenuItem value={'base64'}>Base64 encoded</MenuItem>
            </Select>

            {contentFormat === 'csvfile' ? (
              <Button
                component="label"
                color="primary"
                fullWidth
              >
                {fileRef ? fileRef.name : 'Select CSV'}
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={(event) => {
                    if (event.target.files.length > 0) setFileRef(event.target.files[0])
                  }}
                />
              </Button>
            ) : (
              <TextField
                value={packetContent}
                onChange={event => updateField('packetContent', event.target.value)}
                label="Packets contents"
                placeholder={contentLabels[contentFormat]}
                multiline
                minRows={4}
                maxRows={10}
                fullWidth
                disabled={isSendingContinuously}
              />
            )}
          </CardContent>

          <CardActions>
            {isSendingContinuously ? <Button onClick={toggleContinuous} color="primary" endIcon={<StopIcon />}>
              stop sending
            </Button> : <Button onClick={toggleContinuous} disabled={isSending} color="primary" endIcon={<PlayCircleFilledIcon />} >
              start sending
            </Button>}

            <Button onClick={sendSingle} disabled={isSending || isSendingContinuously} color="secondary" endIcon={<SendIcon />}>
              send one
            </Button>
          </CardActions>
        </Card>

        {lastError && <Alert severity="error">{lastError}</Alert>}

        <Card className={classes.paper}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Packets sent
            </Typography>
            <Typography variant="h5" component="h2">
              {framesSentCount}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

export default App;
