import Papa from 'papaparse';
import ByteEncoder from './cryptii/ByteEncoder';
import TextEncoder from './cryptii/TextEncoder';

const base10toBase64 = (int) => {
    const order = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    const base = order.length;
    let base64String = "";
    let currentValue;
    while (int) {
        currentValue = int % base
        int -= currentValue;
        int /= base;
        base64String = order.charAt(currentValue) + base64String;
    }
    return base64String;
}

const padBinaryString = (string, length) => {
    while (string.length < length) string = '0' + string;
    return string;
}

const uintToBinary = (value, lengthInBits) => {
    const int = Number(value);
    let binary = int.toString(2);
    return padBinaryString(binary, lengthInBits);
}

const intToBinary = (value, lengthInBits) => {
    const int = Number(value);
    const isNegative = int < 0;

    if (isNegative) {
        const binaryString = (Math.pow(2, lengthInBits - 1) + int).toString(2);
        return '1' + padBinaryString(binaryString, lengthInBits - 1);
    }
    else {
        let binary = int.toString(2);
        return '0' + padBinaryString(binary, lengthInBits - 1);
    }
}

const textToBinary = (text, lengthInBits) => {
    const codePoints = TextEncoder.codePointsFromString(text)
    const bytes = TextEncoder.bytesFromCodePoints(codePoints)
    const binaryString = ByteEncoder.binaryStringFromBytes(bytes)

    return padBinaryString(binaryString, lengthInBits);
}

const booleanToBinary = (boolean, lengthInBits) => {
    const isFalsy = boolean === '0' || boolean === 'false' || !boolean;
    const binaryString = isFalsy ? '0' : '1';

    return padBinaryString(binaryString, lengthInBits);
}

const getRecursiveFields = (elements) => {
    const bitLengths = elements.map(element => {
        if (element.type === 'SWITCH') throw new Error('Streams containing switches are not supported at the moment 😭.');
        if (element.elements) return getRecursiveFields(element.elements).flat();
        else {
            const { name, dataType, lengthInBits } = element;
            return { name, dataType, lengthInBits };
        }
    });

    return bitLengths.flat();
}

const getStreamFields = (stream) => {
    const rootElements = stream.configuration.structure.elements;
    return getRecursiveFields(rootElements)
}

const parseCSV = (input) => {
    return new Promise((resolve, reject) => {
        Papa.parse(input, {
            complete: (results) => resolve(results.data),
            error: (error) => reject(error),
        })
    })
}

export const encodeTextToBase64 = async (input, stream) => {
    // for every frame, converts individual values into binary strings, then join them together. Then we convert them to a base 10 number and convert that to base 64.
    // there's probably a better way to do this.
    const frames = await parseCSV(input);

    const fields = getStreamFields(stream);

    const framesAsBinaryStrings = frames.map(frameValues => {
        if (frameValues.length !== fields.length) {
            throw new Error(`Stream has ${fields.length} fields but ${frameValues.length} values were provided for at least one of the packets.`);
        }

        return frameValues.map((value, index) => {
            const { dataType, lengthInBits } = fields[index];
            if (dataType === 'INTEGER') return intToBinary(value, lengthInBits);
            if (dataType === 'UINTEGER') return uintToBinary(value, lengthInBits);
            else if (dataType === 'TEXT') return textToBinary(value, lengthInBits);
            else if (dataType === 'BOOLEAN') return booleanToBinary(value, lengthInBits);
            else {
                throw new Error(`Data type ${dataType} is not supported at the moment 😭`)
            }
        }).join('');
    });


    const encodedFrames = framesAsBinaryStrings.map(binaryString => {
        return base10toBase64(parseInt(binaryString, 2));
    });

    return encodedFrames;
}